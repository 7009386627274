.cartHeader {
    padding: 30px;
}

.cartItems {
}

.totalPrice {
    font-weight: 900;
    font-size: 18px;
    padding: 24px 0;
}

.totalPrice span {
    color: #FBB347;
}


.info {
    color: #9C9C9C;

}

.infoTitle {
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 8px;
}

.infoText {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 18px;
}
.title {
    font-weight: 800;
    font-size: 24px;
    color: #323232;
    margin: 20px 0;
}

.content {
    box-sizing: border-box;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
}