.authPage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.authPageHeader {
    padding: 30px;
    margin-bottom: 30px;
}

.image {
    display: block;
}

.info {
    position: relative;
}

.text {
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #9C9C9C;
    left: 230px;
    bottom: 130px;
    display: block;
    width: 118px;
}

.form {
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 7px 7px 21px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    max-width: 300px;
    width: 100%;
    margin: 0 auto 40px auto;
    position: relative;
}

.button {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #F7C355;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -22px;
    right: 36px;
    cursor: pointer;
}

.button:disabled {
    background: #d9d9d9;
    cursor: not-allowed;
}

.errorMessage {
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: #FF8181;
    margin-bottom: 12px;
}

.button img {
    display: block;
    width: 19px;
}

.error {
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: #FF8181;
    margin-bottom: 12px;

}


.toggleForms {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.toggleButton {
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #323232;
    padding: 8px 10px;
    border-radius: 10px;
    display: inline-block;
}

.toggleButton.active {
    background: #F7C355;
    color: #FFFFFF;
}

.input {
    outline: none;
    border: 1px solid #9C9C9C;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 8px;
    font-weight: 500;
    font-size: 10px;
    color: #323232;
    width: calc(100% - 20px);
    margin-bottom: 4px;
}

.input.error {
    background: #FFEEEE;
    border: 1px solid #FF8181;
    border-radius: 10px;
}

.label {
    display: block;
    font-weight: 500;
    font-size: 10px;
    color: #9C9C9C;
    margin-bottom: 7px;
}

.input::placeholder {
    font-weight: 500;
    font-size: 10px;
    color: #9C9C9C;
}

.input:focus {
    background: #E7E9FF;
    border: 1px solid #3A80D2;
}
