.support {
    padding: 30px;
}
.form {
    max-width: 400px;
    margin: 60px auto;
    background: #FFFFFF;
    box-shadow: 7px 7px 21px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    position: relative;
    padding: 30px;
}

.label {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #9C9C9C;
    margin-bottom: 8px;
    display: block;
}

textarea {
    width: 94%;
    margin: 0;
    display: block;
    border: 1px solid #9C9C9C;
    border-radius: 10px;
    padding: 12px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #9C9C9C;


}


.button {
    background: #F7C355;
    border-radius: 10px;
    padding: 10px 16px;
    border: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 16px;
}

.name {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #323232;
    margin-bottom: 24px;
}

.button:disabled {
    cursor: not-allowed;
    background: #cdcbcb;
}

.img {
    position: absolute;
    bottom: 0;
    right: 20px;
}