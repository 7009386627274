.mainMenu {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(50px);
}

.menuList {
    padding: 30px;
    width: 40%;
    height: 100%;
    background: #FFFFFF;
}

.menuItem {
    margin-top: 20px;
}

.menuItem a {
    font-weight: 900;
    font-size: 14px;
    color: #323232;
}


.menuButton {
    cursor: pointer;
}
