@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800;900&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

*{
    text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    margin: 0;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #323232;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Product_product__4Z28z {


}

.Product_error__3LS90 {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Product_productHeader__2l52t {
    position: relative;
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(251, 157, 40, 0.5));
    min-height: 360px;
    box-shadow: 0 5px 10px rgba(251, 157, 40, 0.5);
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    margin-bottom: 40px;
}

.Product_productImage__2mA3r {
    position: relative;
    overflow: hidden;
}

.Product_productHeader__2l52t img {
    z-index: 7;
    display: block;
    margin: 0 auto;
}

.Product_productHeaderButtons__k-mN- {
    z-index: 9;
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
}

.Product_productHeaderButtons__k-mN- a {
    cursor: pointer;
}

.Product_productInfo__7KGBM {
    padding: 0 30px;
    box-sizing: border-box;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
}

.Product_productTitle__2Eiwh {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Product_productName__3dtfd {
    font-weight: 900;
    font-size: 18px;
    margin: 0;
}

.Product_productPrice__1AUPR {
    font-weight: 900;
    font-size: 18px;
}

.Product_selectSize__2FwtQ {
    display: flex;
    margin-top: 15px;
    margin-bottom: 24px;
}

.Product_sizeButton__3ZJmE {
    height: 55px;
    padding: 0 17px;
    background: #E9EDF6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 30px;
    margin-right: 14px;
    cursor: pointer;
}

.Product_sizeButton__3ZJmE.Product_active__1Gxyy {
    background: #FBB347;
    color: #FFFFFF;
}

.Product_description__2T94h {
    margin-top: 24px;
}

.Product_descriptionTitle__sjcoI {
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 12px;
}

.Product_descriptionText__1PNcv {
    font-weight: bold;
    font-size: 12px;
    color: #9C9C9C;
    margin-bottom: 16px;
}

.Button_button__3QF_k {
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%);
    box-shadow: 0 4px 12px rgba(248, 196, 86, 0.6);
    border-radius: 10px;
    padding: 15px 30px;
    display: block;
    font-weight: 800;
    font-size: 20px;
    color: #FFFFFF;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    min-width: 260px;
}

.Button_button__3QF_k:disabled {
    cursor: not-allowed;
    background: #c6c4c4;
    box-shadow: none;
}


.SelectPopup_select__RkA8q {
    /*width: 255px;*/
    display: flex;
    margin-bottom: 20px;
}

.SelectPopup_selectPopup__10odd {
    position: absolute;
    left: 0;
    top: -35px;
    margin-top: 15px;
    background: #EBECF0;
    overflow: hidden;
    padding: 10px 0;
    width: 220px;
    max-height: 180px;
    overflow-y: auto;
    border: 1px solid rgba(255, 255, 255, 0.16);
    box-sizing: border-box;
    box-shadow: -10px -10px 20px rgba(250, 251, 255, 0.4), 10px 10px 30px rgba(166, 171, 189, 0.6);
    border-radius: 20px;
}

.SelectPopup_selectPopup__10odd ul {
    overflow: hidden;
}

.SelectPopup_selectPopup__10odd li {
    font-weight: bold;
    font-size: 16px;
    color: #868686;
    padding: 12px 20px;
    cursor: pointer;
}

.SelectPopup_selectPopup__10odd li.SelectPopup_active__2R_5T,
.SelectPopup_selectPopup__10odd li:hover {
    color: #323232;
}

.SelectPopup_selectPopup__10odd li.SelectPopup_active__2R_5T {
    background: #e0e0e3;
}

.SelectPopup_selectLabel__1QM1A {
    display: flex;
    align-items: center;
    position: relative;

}

.SelectPopup_select__RkA8q b {
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
}

.SelectPopup_selectLabel__1QM1A span {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FBB347;
    cursor: pointer;
}
.Error_error__1Ykqe {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.Error_text__2GK4p {
    font-weight: bold;
    font-size: 16px;
    color: #9C9C9C;
}
.CheckSelect_check__WUEpw {
    margin-bottom: 20px;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #FBB347;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.CheckSelect_title__1bheX {
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
    margin-bottom: 15px;
    display: block;
}

.CheckSelect_checkItems__3L-0t {
    display: flex;
}

.CheckSelect_checkItem__3sAgE {
    margin-right: 15px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.Loader_loader-wrapper__3Dq81 {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;

}
.Loader_loader-box__3SKPf {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.Loader_icon__2hNLz {
    font-size: 5rem;
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgb(255, 255, 255);
    border-radius: 150px;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: Loader_loaderSize__fi77E 2.5s infinite ease-in-out, Loader_loaderColor__C-Mjs 7.5s infinite ease-in-out;
            animation: Loader_loaderSize__fi77E 2.5s infinite ease-in-out, Loader_loaderColor__C-Mjs 7.5s infinite ease-in-out;
    color: #F7C355;
    box-shadow: 0 0 15px #fff;
}
@-webkit-keyframes Loader_loaderSize__fi77E {
    0% {
        -webkit-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }
    16% {
        -webkit-transform: scale(0) rotate(0);
                transform: scale(0) rotate(0);
    }
    50% {
        -webkit-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }
    80% {
        -webkit-transform: scale(1) rotate(-360deg);
                transform: scale(1) rotate(-360deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(-360deg);
                transform: scale(1) rotate(-360deg);
    }
}
@keyframes Loader_loaderSize__fi77E {
    0% {
        -webkit-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }
    16% {
        -webkit-transform: scale(0) rotate(0);
                transform: scale(0) rotate(0);
    }
    50% {
        -webkit-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }
    80% {
        -webkit-transform: scale(1) rotate(-360deg);
                transform: scale(1) rotate(-360deg);
    }
    100% {
        -webkit-transform: scale(1) rotate(-360deg);
                transform: scale(1) rotate(-360deg);
    }
}

.CartIcon_cartIcon__2Us1M {
    padding: 3px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CartIcon_cartIcon__2Us1M.CartIcon_active__34m5J {
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #E5E5E5 0%, rgba(229, 229, 229, 0) 100%);
    border-radius: 100000px;
    border: 2px solid #FFFFFF;
}

.CartIcon_cartIcon__2Us1M.CartIcon_active__34m5J span {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: block;
    margin-left: 5px;
}
.Cart_cartHeader__3mKQj {
    padding: 30px;
}

.Cart_cartItems__18m0_ {
}

.Cart_totalPrice__3Al4E {
    font-weight: 900;
    font-size: 18px;
    padding: 24px 0;
}

.Cart_totalPrice__3Al4E span {
    color: #FBB347;
}


.Cart_info__1Dr5U {
    color: #9C9C9C;

}

.Cart_infoTitle__OjYp0 {
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 8px;
}

.Cart_infoText__2DU5B {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 18px;
}
.Cart_title__1_2px {
    font-weight: 800;
    font-size: 24px;
    color: #323232;
    margin: 20px 0;
}

.Cart_content__34SnA {
    box-sizing: border-box;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
}
.CartItemCard_cartItem__1hEIK {
    display: flex;
    margin-bottom: 24px;

}

.CartItemCard_productImage__LeilV {
    width: 170px;
    height: 110px;
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 5px 10px rgba(251, 157, 40, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

}
.CartItemCard_productImage__LeilV img{
    z-index: 3;
}

.CartItemCard_circles__2uD8h {
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 1;
}

.CartItemCard_productInfo__sGuEr {
    padding: 0 !important;
    margin-left: 16px;
}
.CartItemCard_productTitle__3HdKD {
    font-weight: 900;
    font-size: 14px;
    margin-bottom: 8px;
}

.CartItemCard_productInfoText__1WuTt {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
}

.CartItemCard_productPrice__2coMW {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
}

.CartItemCard_productCount__ykxtv {
    display: flex;
    margin: 8px 0;
}
.CartItemCard_productCount__ykxtv button {
    width: 20px;
    height: 20px;
    background: #FBB347;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.CartItemCard_productCount__ykxtv span {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    margin: 0 8px;
}

.ButtonBack_buttonBack__2ureB {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: inherit;
    text-decoration: none;
}

.ButtonBack_buttonBack__2ureB span {
    font-weight: 900;
    font-size: 24px;
    margin-left: 10px;
}

.DesctopNavigation_mainMenu__2QrL2 {
    padding: 10px 30px;
    background: #323232;
}

.DesctopNavigation_mainMenu__2QrL2.DesctopNavigation_whiteMode__1ZLH9 {
    background: #FFFFFF;
}

.DesctopNavigation_content__2ONQQ {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DesctopNavigation_menuList__1Jj1r {
    display: flex;
}

.DesctopNavigation_menuItem__3id14 {
    margin-right: 20px;
}

.DesctopNavigation_mainMenu__2QrL2.DesctopNavigation_whiteMode__1ZLH9 .DesctopNavigation_menuItem__3id14 a{
    color: #323232;
}

.DesctopNavigation_menuItem__3id14 a {
    font-weight: 800;
    font-size: 16px;
    color: #FFFFFF;
}


.DesctopNavigation_menuButton__2MlW0 {
}

.About_about__1I_cf {
    max-width: 560px;
    margin: 0 auto;
    width: 100%;
}

.About_aboutHeader__1sI0e {
    padding: 30px;
}

.About_info__3NPtM {
    padding: 0 30px;
}

.About_title__2NEXK {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 14px;
}

.About_content__3lGNI {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.About_text__29nx3 {
    font-weight: bold;
    font-size: 12px;
    color: #9C9C9C;
    max-width: 150px;
    margin-left: 20px;
}

.About_text__29nx3:nth-child(odd) {
    margin-right: 20px;
    margin-left: 0;
}

img {
    display: block;
}

.About_workPlan__2tCDo {
    font-weight: 800;
    font-size: 72px;
    width: 125px;
    height: 125px;
    border: 3px solid #FFFFFF;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3);
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.About_workPlan__2tCDo::before, .About_workPlan__2tCDo::after {
    position: absolute;
    content: "";
    width: 120px;
    height: 120px;
    background: radial-gradient(128.67% 128.67% at 50% 50%, #F7C355 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    top: -50%;
    left: -50%;
    z-index: -1;
}

.About_workPlan__2tCDo::before {
    top: -50%;
    left: -50%;
}

.About_workPlan__2tCDo::after {
    top: 50%;
    left: 50%;
}

.About_workPlan__2tCDo span {
    display: block;
    font-weight: 800;
    font-size: 16px;
}
.Menu_menu__CfAbw {
    max-width: 900px;
    margin: 0 auto;
}

.Menu_menuHeader__1xknK {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}

.Menu_category__3NUHV {
    display: flex;
    padding: 0 30px 15px 30px;
    overflow-x: auto;
}

.Menu_categoryItem__9_v9G {
    border: 1px solid #323232;
    border-radius: 10px;
    padding: 10px 20px;
    margin-right: 8px;
    cursor: pointer;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}

.Menu_categoryLoader__1QmQf {
    margin-right: 10px;
}

.Menu_categoryItem__9_v9G:hover, .Menu_categoryItem__9_v9G.Menu_active__32AW2 {
    background: #323232;
    color: #FFFFFF;
}

.Menu_productCards__1Pejn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    justify-content: space-between;
}

.Menu_productLoader__2PzKZ {
    margin-bottom: 24px;
}

.MenuItemCard_productCard__1GKv0 {
    overflow: hidden;
    width: 140px;
    height: 170px;
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 5px 10px rgba(251, 157, 40, 0.5);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-bottom: 24px;
    margin-right: 10px;
    margin-left: 10px;
}

.MenuItemCard_productTitle__3Tf7u {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding-left: 22px;
    z-index: 3;
    padding-top: 12px;
}

.MenuItemCard_productButtons__3EkfR {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    z-index: 3;
}

.MenuItemCard_productButton__2q1zB {
    background: #323232;
    border-radius: 1000px;
    padding: 8px 12px;
    font-weight: 700;
    font-size: 13px;
    color: #FFFFFF;
    text-decoration: none;
    cursor:pointer;
}

.MenuItemCard_productButton__2q1zB.MenuItemCard_active__1gWFU {
    background: #F7C355;
    box-shadow: 4px 6px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.MenuItemCard_productImg__2CgrI {
    display: block;
    position: absolute;
    z-index: 1;
    width: 90%;
    top:60%;
    left:50%;
    -webkit-transform:translate(-50%, -50%);
            transform:translate(-50%, -50%);
}

.MenuItemCard_circles__3MziE {
    display: block;
    position: absolute;
}
.Restaurants_restaurants__2U-g5 {
    max-width: 560px;
    margin: 0 auto;
    width: 100%;
    padding: 30px;
}

.Restaurants_restaurantsItems__1nQdI {
    margin-top: 30px;
}

.RestaurantCard_cartItem__2VkWn {
    display: flex;
    margin-bottom: 24px;
    width: auto;
}


.RestaurantCard_restaurantImage__1UKS_ {
    width: 150px;
    height: 200px;
    background: #DADADA;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

}
.RestaurantCard_restaurantImage__1UKS_ img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
}

.RestaurantCard_restaurantInfo__3UGj_ {
    padding: 0 !important;
    margin-left: 16px;
    width: 135px;
    position: relative;
}
.RestaurantCard_restaurantTitle__7gTen {
    font-weight: 800;
    font-size: 16px;
    color: #323232;
    margin-bottom: 12px;
}

.RestaurantCard_restaurantInfoText__2vVaY {
    font-weight: bold;
    font-size: 12px;
    color: #9C9C9C;
}

.RestaurantCard_restaurantButton__2DIh7 {
    position: absolute;
    padding: 6px 14px;
    background: #F7C355;
    border-radius: 1000px;
    text-decoration: none;
    font-weight: 800;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    z-index: 10;
    bottom: 14px;
}
.Vacancies_vacancies__32-CY {
    padding: 30px;
}
.Vacancies_vacanciesItems__1s0wL {
    max-width: 900px;
    width: 100%;
    margin: 30px auto 0 auto;
}

.Vacancies_cartItem__YDlyh {
    display: flex;
    margin-bottom: 24px;
    width: auto;
}

.Vacancies_vacanciesImage__1JQ_6 {
    width: 170px;
    height: 110px;
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 5px 10px rgba(251, 157, 40, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.Vacancies_vacanciesImage__1JQ_6 img{
    z-index: 3;
}

.Vacancies_circles__TnU2X {
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 1;
}

.Vacancies_vacanciesInfo__1ZGKx {
    padding: 0 !important;
    margin-left: 16px;
    width: 110px;
}
.Vacancies_vacanciesTitle__2bzKG {
    font-weight: 900;
    font-size: 14px;
    margin-bottom: 10px;
}

.Vacancies_vacanciesInfoText__2Cfv2 {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #323232;
}

.Vacancies_vacanciesButton__2Vc0l {
    font-weight: 800;
    font-size: 13px;
    color: #FFFFFF;
    background: #F7C355;
    padding: 6px 14px;
    border-radius: 7px;
    text-decoration: none;
}
.Vacancy_vacancy__3H3Oo {

}

.Vacancy_vacancyHeader__UJUBz {
    position: relative;
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(251, 157, 40, 0.5));
    min-height: 360px;
    box-shadow: 0 5px 10px rgba(251, 157, 40, 0.5);
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 40px;
    overflow: hidden;
}

.Vacancy_vacancyImage__1rE6j {
    position: relative;
    overflow: hidden;
}

.Vacancy_vacancyHeader__UJUBz img {
    z-index: 7;
    display: block;
    margin: 0 auto;
}

.Vacancy_vacancyHeaderButtons__1a_Q2 {
    z-index: 9;
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
}

.Vacancy_vacancyHeaderButtons__1a_Q2 a {
    cursor: pointer;
}

.Vacancy_vacancyInfo__1xCrF {
    padding: 0 30px;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
}

.Vacancy_vacancyTitle__YbZH9 {
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 24px;
}

.Vacancy_vacancyName__10rnd {
    font-weight: 900;
    font-size: 18px;
    margin: 0;
}

.Vacancy_vacancyPrice__3sN2h {
    font-weight: 900;
    font-size: 18px;
}

.Vacancy_selectSize__2C4VA {
    display: flex;
    margin-top: 15px;
    margin-bottom: 24px;
}

.Vacancy_sizeButton__1qioQ {
    width: 55px;
    height: 55px;
    background: #E9EDF6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 30px;
    margin-right: 14px;
    cursor: pointer;
}

.Vacancy_sizeButton__1qioQ.Vacancy_active__WB1eO {
    background: #FBB347;
    color: #FFFFFF;
}

.Vacancy_description__1ItwF {
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 18px;
}

.Vacancy_text__1eCxv {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    margin-bottom: 16px;
}

.Vacancy_descriptionTitle__rGaY0 {
    font-weight: 900;
    font-size: 18px;
    margin-bottom: 12px;
}

.Vacancy_descriptionText__1o18q {
    font-weight: bold;
    font-size: 12px;
    color: #9C9C9C;
    margin-bottom: 16px;
}

.Vacancy_list__K3aSm {
    margin-bottom: 16px;
}

.Vacancy_listItem__1MG2L {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #9C9C9C;
    padding-left: 20px;
    position: relative;
    display: block;
}

.Vacancy_listItem__1MG2L::before {
    position: absolute;
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FBB347;
    left: 0;
}
.Main_main__3ZzQt {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.Main_mainContent__2dakV {
    padding: 30px;
    max-width: 900px;
    margin: 0 auto;
}

.Main_mainInfo__1Y01h {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}


.Main_mainHeader__3W3di {
    padding: 30px;
    overflow: hidden;
    min-height: 360px;
    background: linear-gradient(134.51deg, rgba(247, 195, 85, 0.5) -24.91%, #F7C355 49.71%, rgba(247, 195, 85, 0) 136.21%);
}

.Main_mainNavigation__2fnIU {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 90px;
}

.Main_mainInfoText__10oLm {
    margin-top: 40px;
    position: absolute;
    max-width: 240px;
    width: 100%;
    z-index: 10;
}

.Main_mainImage__1WOyK {
    display: block;
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: 5;
    height: 380px;

}

.Main_mobileImage__3EXf9 {
    display: block;
    position: absolute;
    z-index: 5;
    height: 380px;
    bottom: -300px;
    left: 52px;
}
.Main_mainImage1__3RXNi {
    right: 80px;
    z-index: 1;
}
.Main_mainImage3__1KT7a {
    right: -120px;
    z-index: 1;
}

.Main_mainButton__16ep4 {
    background: #323232;
    border-radius: 7px;
    font-weight: 800;
    font-size: 13px;
    color: #FFFFFF;
    padding: 8px 14px;
    cursor: pointer;
    display: inline-block;
}

.Main_mainTitle__WduQ_ {
    font-weight: 900;
    font-size: 28px;
    color: #323232;
    margin-bottom: 12px;
    display: block;
}

.Main_mainText__-yAHG {
    font-weight: 800;
    font-size: 14px;
    letter-spacing: 0.1em;
    margin-bottom: 12px;
    color: #ffffff;
}


.Main_popular__O32jb {
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #FBB347;
}

.Main_popular__O32jb span {
    display: block;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #323232;
}

.Main_title__24gTZ {
    font-weight: 900;
    font-size: 18px;
    color: #323232;
    margin-bottom: 25px;
}

.Main_title__24gTZ span {
    color: #FBB347;
}


.Main_info__2GbNm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.Main_infoTitle__3OzZP {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 14px;
}

.Main_content__1aTog {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.Main_text__3Fgoy {
    font-weight: bold;
    font-size: 14px;
    color: #9C9C9C;
    max-width: 150px;
    margin-left: 20px;
}


img {
    display: block;
}

.Main_workPlan__1FEdS {
    font-weight: 800;
    font-size: 72px;
    width: 125px;
    height: 125px;
    border: 3px solid #FFFFFF;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3);
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.Main_workPlan__1FEdS::before {
    position: absolute;
    content: "";
    width: 120px;
    height: 120px;
    background: radial-gradient(128.67% 128.67% at 50% 50%, #F7C355 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    top: -50%;
    left: -50%;
    z-index: -1;
}

.Main_workPlan__1FEdS::before {
    top: -50%;
    left: -50%;
}


.Main_workPlan__1FEdS span {
    display: block;
    font-weight: 800;
    font-size: 16px;
}


.Main_restaurantsItems__tpQDH {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.Main_slider__2HziY {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 50px 0;
}

.Main_prev__1fmaV, .Main_next__P_lWC {
    width: 45px;
    height: 45px;
    border: none;
    background: #F7C355;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Main_prev__1fmaV img, .Main_next__P_lWC img {
    width: 20px;
}

.Main_prev__1fmaV img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.Main_popularItems__3uLAw {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.Navigation_mainMenu__1Pu6F {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
}

.Navigation_menuList__2qQAl {
    padding: 30px;
    width: 40%;
    height: 100%;
    background: #FFFFFF;
}

.Navigation_menuItem__3b7HI {
    margin-top: 20px;
}

.Navigation_menuItem__3b7HI a {
    font-weight: 900;
    font-size: 14px;
    color: #323232;
}


.Navigation_menuButton__2hRIT {
    cursor: pointer;
}

.Footer_footer__1w0lV {
    background: #323232;
}

.Footer_footer__1w0lV img {
    width: 50px;
}

.Footer_footerContent__aHfIE {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Footer_copyright__2OWH4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
    color: #FFFFFF;
    margin-left: 20px;
}


.SlideItem_slide__1AzWl {
    width: 250px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 25px;
}

.SlideItem_slideImg__v7x3k {
    width: 100%;
    height: 150px;
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 5px 10px rgba(251, 157, 40, 0.5);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 15px;
}

.SlideItem_slideImg__v7x3k img{

}
.SlideItem_slideInfo__1oOT- {
    display: flex;
    justify-content: space-between;
}

.SlideItem_title__3olVF {
    font-weight: 900;
    font-size: 18px;
    color: #F7C355;
}

.SlideItem_price__1j-o4{
    font-weight: 900;
    font-size: 18px;
    color: #323232;

}

.AuthPage_authPage__18C7K {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.AuthPage_authPageHeader__3Vm32 {
    padding: 30px;
    margin-bottom: 30px;
}

.AuthPage_image__2Q8Wa {
    display: block;
}

.AuthPage_info__1mzro {
    position: relative;
}

.AuthPage_text__3AOIt {
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #9C9C9C;
    left: 230px;
    bottom: 130px;
    display: block;
    width: 118px;
}

.AuthPage_form__3Q2OC {
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 7px 7px 21px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    max-width: 300px;
    width: 100%;
    margin: 0 auto 40px auto;
    position: relative;
}

.AuthPage_button__3iWQ9 {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #F7C355;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -22px;
    right: 36px;
    cursor: pointer;
}

.AuthPage_button__3iWQ9:disabled {
    background: #d9d9d9;
    cursor: not-allowed;
}

.AuthPage_errorMessage__Zct-_ {
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: #FF8181;
    margin-bottom: 12px;
}

.AuthPage_button__3iWQ9 img {
    display: block;
    width: 19px;
}

.AuthPage_error__2FAkk {
    font-weight: 500;
    font-size: 8px;
    line-height: 10px;
    color: #FF8181;
    margin-bottom: 12px;

}


.AuthPage_toggleForms__1eKEv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.AuthPage_toggleButton__3oSkh {
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #323232;
    padding: 8px 10px;
    border-radius: 10px;
    display: inline-block;
}

.AuthPage_toggleButton__3oSkh.AuthPage_active__Kqqwm {
    background: #F7C355;
    color: #FFFFFF;
}

.AuthPage_input__18Xde {
    outline: none;
    border: 1px solid #9C9C9C;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 8px;
    font-weight: 500;
    font-size: 10px;
    color: #323232;
    width: calc(100% - 20px);
    margin-bottom: 4px;
}

.AuthPage_input__18Xde.AuthPage_error__2FAkk {
    background: #FFEEEE;
    border: 1px solid #FF8181;
    border-radius: 10px;
}

.AuthPage_label__1aEMR {
    display: block;
    font-weight: 500;
    font-size: 10px;
    color: #9C9C9C;
    margin-bottom: 7px;
}

.AuthPage_input__18Xde::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 10px;
    color: #9C9C9C;
}

.AuthPage_input__18Xde:-ms-input-placeholder {
    font-weight: 500;
    font-size: 10px;
    color: #9C9C9C;
}

.AuthPage_input__18Xde::placeholder {
    font-weight: 500;
    font-size: 10px;
    color: #9C9C9C;
}

.AuthPage_input__18Xde:focus {
    background: #E7E9FF;
    border: 1px solid #3A80D2;
}

.NotFoundPage_notFound__1oT6f {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NotFoundPage_content__bZR3A {
    max-width: 300px;

}
.NotFoundPage_title__DYkFx {
    font-weight: 200;
    font-size: 36px;
    color: #8D8D8D;
}

.NotFoundPage_image__36IS_ {
    display: flex;
    margin-bottom: 36px;
}

.NotFoundPage_image__36IS_ span {
    display: block;
    font-weight: 800;
    font-size: 96px;
    line-height: 117px;
    color: #323232;
}

.NotFoundPage_text__QglWK {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #9C9C9C;
}

.Support_support__2LVV9 {
    padding: 30px;
}
.Support_form__p5liK {
    max-width: 400px;
    margin: 60px auto;
    background: #FFFFFF;
    box-shadow: 7px 7px 21px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    position: relative;
    padding: 30px;
}

.Support_label__2vTdy {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #9C9C9C;
    margin-bottom: 8px;
    display: block;
}

textarea {
    width: 94%;
    margin: 0;
    display: block;
    border: 1px solid #9C9C9C;
    border-radius: 10px;
    padding: 12px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #9C9C9C;


}


.Support_button__1iDJw {
    background: #F7C355;
    border-radius: 10px;
    padding: 10px 16px;
    border: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 16px;
}

.Support_name__F5Eww {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #323232;
    margin-bottom: 24px;
}

.Support_button__1iDJw:disabled {
    cursor: not-allowed;
    background: #cdcbcb;
}

.Support_img__1GE0S {
    position: absolute;
    bottom: 0;
    right: 20px;
}
