.productCard {
    overflow: hidden;
    width: 140px;
    height: 170px;
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 5px 10px rgba(251, 157, 40, 0.5);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-bottom: 24px;
    margin-right: 10px;
    margin-left: 10px;
}

.productTitle {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding-left: 22px;
    z-index: 3;
    padding-top: 12px;
}

.productButtons {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    z-index: 3;
}

.productButton {
    background: #323232;
    border-radius: 1000px;
    padding: 8px 12px;
    font-weight: 700;
    font-size: 13px;
    color: #FFFFFF;
    text-decoration: none;
    cursor:pointer;
}

.productButton.active {
    background: #F7C355;
    box-shadow: 4px 6px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.productImg {
    display: block;
    position: absolute;
    z-index: 1;
    width: 90%;
    top:60%;
    left:50%;
    transform:translate(-50%, -50%);
}

.circles {
    display: block;
    position: absolute;
}