.buttonBack {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: inherit;
    text-decoration: none;
}

.buttonBack span {
    font-weight: 900;
    font-size: 24px;
    margin-left: 10px;
}
