.cartIcon {
    padding: 3px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartIcon.active {
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #E5E5E5 0%, rgba(229, 229, 229, 0) 100%);
    border-radius: 100000px;
    border: 2px solid #FFFFFF;
}

.cartIcon.active span {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: block;
    margin-left: 5px;
}