.notFound {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    max-width: 300px;

}
.title {
    font-weight: 200;
    font-size: 36px;
    color: #8D8D8D;
}

.image {
    display: flex;
    margin-bottom: 36px;
}

.image span {
    display: block;
    font-weight: 800;
    font-size: 96px;
    line-height: 117px;
    color: #323232;
}

.text {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #9C9C9C;
}
