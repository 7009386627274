.main {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.mainContent {
    padding: 30px;
    max-width: 900px;
    margin: 0 auto;
}

.mainInfo {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}


.mainHeader {
    padding: 30px;
    overflow: hidden;
    min-height: 360px;
    background: linear-gradient(134.51deg, rgba(247, 195, 85, 0.5) -24.91%, #F7C355 49.71%, rgba(247, 195, 85, 0) 136.21%);
}

.mainNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 90px;
}

.mainInfoText {
    margin-top: 40px;
    position: absolute;
    max-width: 240px;
    width: 100%;
    z-index: 10;
}

.mainImage {
    display: block;
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: 5;
    height: 380px;

}

.mobileImage {
    display: block;
    position: absolute;
    z-index: 5;
    height: 380px;
    bottom: -300px;
    left: 52px;
}
.mainImage1 {
    right: 80px;
    z-index: 1;
}
.mainImage3 {
    right: -120px;
    z-index: 1;
}

.mainButton {
    background: #323232;
    border-radius: 7px;
    font-weight: 800;
    font-size: 13px;
    color: #FFFFFF;
    padding: 8px 14px;
    cursor: pointer;
    display: inline-block;
}

.mainTitle {
    font-weight: 900;
    font-size: 28px;
    color: #323232;
    margin-bottom: 12px;
    display: block;
}

.mainText {
    font-weight: 800;
    font-size: 14px;
    letter-spacing: 0.1em;
    margin-bottom: 12px;
    color: #ffffff;
}


.popular {
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #FBB347;
}

.popular span {
    display: block;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #323232;
}

.title {
    font-weight: 900;
    font-size: 18px;
    color: #323232;
    margin-bottom: 25px;
}

.title span {
    color: #FBB347;
}


.info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.infoTitle {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 14px;
}

.content {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.text {
    font-weight: bold;
    font-size: 14px;
    color: #9C9C9C;
    max-width: 150px;
    margin-left: 20px;
}


img {
    display: block;
}

.workPlan {
    font-weight: 800;
    font-size: 72px;
    width: 125px;
    height: 125px;
    border: 3px solid #FFFFFF;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3);
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.workPlan::before {
    position: absolute;
    content: "";
    width: 120px;
    height: 120px;
    background: radial-gradient(128.67% 128.67% at 50% 50%, #F7C355 0%, rgba(255, 255, 255, 0) 100%);
    filter: blur(50px);
    top: -50%;
    left: -50%;
    z-index: -1;
}

.workPlan::before {
    top: -50%;
    left: -50%;
}


.workPlan span {
    display: block;
    font-weight: 800;
    font-size: 16px;
}


.restaurantsItems {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.slider {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 50px 0;
}

.prev, .next {
    width: 45px;
    height: 45px;
    border: none;
    background: #F7C355;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev img, .next img {
    width: 20px;
}

.prev img {
    transform: rotate(180deg);
}

.popularItems {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}