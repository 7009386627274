.error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.text {
    font-weight: bold;
    font-size: 16px;
    color: #9C9C9C;
}