.cartItem {
    display: flex;
    margin-bottom: 24px;
    width: auto;
}


.restaurantImage {
    width: 150px;
    height: 200px;
    background: #DADADA;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

}
.restaurantImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
}

.restaurantInfo {
    padding: 0 !important;
    margin-left: 16px;
    width: 135px;
    position: relative;
}
.restaurantTitle {
    font-weight: 800;
    font-size: 16px;
    color: #323232;
    margin-bottom: 12px;
}

.restaurantInfoText {
    font-weight: bold;
    font-size: 12px;
    color: #9C9C9C;
}

.restaurantButton {
    position: absolute;
    padding: 6px 14px;
    background: #F7C355;
    border-radius: 1000px;
    text-decoration: none;
    font-weight: 800;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    z-index: 10;
    bottom: 14px;
}