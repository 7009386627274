.about {
    max-width: 560px;
    margin: 0 auto;
    width: 100%;
}

.aboutHeader {
    padding: 30px;
}

.info {
    padding: 0 30px;
}

.title {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 14px;
}

.content {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.text {
    font-weight: bold;
    font-size: 12px;
    color: #9C9C9C;
    max-width: 150px;
    margin-left: 20px;
}

.text:nth-child(odd) {
    margin-right: 20px;
    margin-left: 0;
}

img {
    display: block;
}

.workPlan {
    font-weight: 800;
    font-size: 72px;
    width: 125px;
    height: 125px;
    border: 3px solid #FFFFFF;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3);
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.workPlan::before, .workPlan::after {
    position: absolute;
    content: "";
    width: 120px;
    height: 120px;
    background: radial-gradient(128.67% 128.67% at 50% 50%, #F7C355 0%, rgba(255, 255, 255, 0) 100%);
    filter: blur(50px);
    top: -50%;
    left: -50%;
    z-index: -1;
}

.workPlan::before {
    top: -50%;
    left: -50%;
}

.workPlan::after {
    top: 50%;
    left: 50%;
}

.workPlan span {
    display: block;
    font-weight: 800;
    font-size: 16px;
}