.slide {
    width: 250px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 25px;
}

.slideImg {
    width: 100%;
    height: 150px;
    background: linear-gradient(127.74deg, #F7C355 9.86%, #FD860E 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 5px 10px rgba(251, 157, 40, 0.5);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 15px;
}

.slideImg img{

}
.slideInfo {
    display: flex;
    justify-content: space-between;
}

.title {
    font-weight: 900;
    font-size: 18px;
    color: #F7C355;
}

.price{
    font-weight: 900;
    font-size: 18px;
    color: #323232;

}
