* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;

}
.loader-box {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.icon {
    font-size: 5rem;
    transform: scale(1);
    background-color: rgb(255, 255, 255);
    border-radius: 150px;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: loaderSize 2.5s infinite ease-in-out, loaderColor 7.5s infinite ease-in-out;
    color: #F7C355;
    box-shadow: 0 0 15px #fff;
}
@keyframes loaderSize {
    0% {
        transform: scale(1) rotate(0);
    }
    16% {
        transform: scale(0) rotate(0);
    }
    50% {
        transform: scale(1) rotate(0);
    }
    80% {
        transform: scale(1) rotate(-360deg);
    }
    100% {
        transform: scale(1) rotate(-360deg);
    }
}
